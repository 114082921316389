<template>
  <div>
    <slot />
  </div>
</template>

<style>
.carousel {
  --vc-nav-background: transparent;
}
</style>
